const fields = {
  modelName: '*',
  dataStructureType: '*',
  dataArea: '*',
  type: '*',
  name: '*',
  note: '*',
  host: {
    exclude: ['API' , 'KAFKA']
  },
  port: {
    exclude: ['API' , 'KAFKA']
  },
  protocol: 'FTP',
  userName: {
    exclude: ['API' , 'KAFKA']
  },
  password: {
    exclude: ['API' , 'KAFKA']
  },
  database: {
    exclude: ['FTP' , 'API' , 'KAFKA']
  },
  other: {
    exclude: ['FTP' , 'API' , 'KAFKA']
  },
  apiUrl: 'API',
  apiMethod: 'API',
  version: 'KAFKA',
  brokerList: 'KAFKA',
  topic: 'KAFKA',
  dataPath: 'API',
  groupId: 'KAFKA'
}

const rules = {
  // modelName: {
  //   required: true,
  //   requiredExclude: []
  // },
  // dataStructureType: {
  //   required: true,
  //   requiredExclude: []
  // },
  dataPath: {
    required: true,
    requiredExclude: []
  },

  // groupId: {
  //   required: true,
  //   requiredExclude: []
  // },
  // dataArea: {
  //   required: true,
  //   requiredExclude: []
  // },
  type: {
    required: true,
    requiredExclude: []
  },
  name: {
    required: true,
    requiredExclude: []
  },
  host: {
    required: true,
    requiredExclude: []
  },
  port: {
    required: true,
    requiredExclude: []
  },
  userName: {
    required: true,
    requiredExclude: []
  },
  protocol: {
    required: true,
    requiredExclude: []
  },
  apiUrl: {
    required: true,
    requiredExclude: []
  },
  brokerList: {
    required: true,
    requiredExclude: []
  },
  apiMethod: {
    required: true,
    requiredExclude: []
  }
}

export function fieldDisplay(fieldName , type){
  if(fields[fieldName]){
    if(fields[fieldName] === '*'){
      return true;
    }else{
      if(typeof fields[fieldName] === 'string'){
        if(fields[fieldName] === type){
          return true;
        }else{
          return false;
        }
      }else if(typeof fields[fieldName] === 'object' && (fields[fieldName] instanceof Array)){
        return !!fields[fieldName].includes(type);
      }else if(typeof fields[fieldName] === 'object' && (fields[fieldName] instanceof Object)){
        if(fields[fieldName]['exclude']){
          return !fields[fieldName]['exclude'].includes(type);
        }
        if(fields[fieldName]['display']){
          return fields[fieldName]['display'].includes(type);
        }
      }
    }
  }else{
    return false;
  }
}

export function fieldVerification(context , type){
  let obj = {} , result = true;
  Object.keys(fields).forEach(
    key => {
      if(fieldDisplay(key , type)){
        obj[key] = context[key];
      }
    }
  )
  Object.keys(obj).forEach(
    key => {
      if(rules[key] && rules[key].required){
        if(!rules[key].requiredExclude.includes(type)){
          if(!obj[key]){
            result = false;
          }
        }
      }
    }
  )
  return result;
}
