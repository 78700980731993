<template>
  <m-list-construction :title="$t('Datasource')">
    <template slot="conditions">
      <m-conditions @on-conditions="_onConditions">
        <template slot="button-group">
          <el-button
            icon="el-icon-plus"
            type="primary"
            size="small"
            @click="_create(null)"
            >新增数据源</el-button
          >
          <el-dialog
            append-to-body
            :title="item ? `编辑数据源` : `新增数据源`"
            v-if="dialogVisible"
            :visible.sync="dialogVisible"
            width="70%"
          >
            <m-create-data-source
              :item="item"
              @onUpdate="onUpdate"
              @close="close"
            ></m-create-data-source>
          </el-dialog>
        </template>
      </m-conditions>
    </template>
    <template slot="content">
      <template v-if="datasourcesList.length || total > 0">
        <m-list
          @on-update="_onUpdate"
          :datasources-list="datasourcesList"
          :page-no="searchParams.pageNo"
          :page-size="searchParams.pageSize"
        ></m-list>
        <div class="page-box">
          <el-pagination
            background
            @current-change="_page"
            @size-change="_pageSize"
            :page-size="searchParams.pageSize"
            :current-page.sync="searchParams.pageNo"
            :page-sizes="[10, 30, 50]"
            layout="sizes, prev, pager, next, jumper"
            :total="total"
          >
          </el-pagination>
        </div>
      </template>
      <template v-if="!datasourcesList.length && total <= 0">
        <no-data></no-data>
      </template>
      <m-spin :is-spin="isLoading" :is-left="false"> </m-spin>
    </template>
  </m-list-construction>
</template>
<script>
import * as _ from "lodash";
import { mapActions } from "vuex";
import mList from "./_source/list";
import mSpin from "../spin/spin";
// import mNoData from '@/view-module/dolphin-scheduler/common/components/noData/noData'
import mCreateDataSource from "./_source/createDataSource";
import listUrlParamHandle from "@/mixins/listUrlParamHandle";
import mConditions from "../conditions/conditions";
import mListConstruction from "../listConstruction/listConstruction";

export default {
  name: "datasource-indexP",
  data() {
    return {
      // loading
      isLoading: true,
      // Total number of articles
      total: 20,
      // data sources(List)
      datasourcesList: [],
      searchParams: {
        // Number of pages per page
        pageSize: 10,
        // Number of pages
        pageNo: 1,
        // Search value
        searchVal: "",
      },
      dialogVisible: false,
      item: {},
    };
  },
  mixins: [listUrlParamHandle],
  props: {},
  methods: {
    ...mapActions("datasource", ["getDatasourcesListP"]),
    /**
     * create data source
     */
    _create(item) {
      this.item = item;
      this.dialogVisible = true;
    },
    onUpdate() {
      this._debounceGET("false");
      this.dialogVisible = false;
    },
    close() {
      this.dialogVisible = false;
    },
    /**
     * page
     */
    // _page (val) {
    //   this.searchParams.pageNo = val
    // },
    // _pageSize (val) {
    //   this.searchParams.pageSize = val
    // },
    /**
     * conditions event
     */
    _onConditions(o) {
      this.searchParams = _.assign(this.searchParams, o);
      this.searchParams.pageNo = 1;
      this._debounceGET();
    },
    /**
     * get data(List)
     */
    _getList(flag) {
      this.isLoading = !flag;
      this.getDatasourcesListP(this.searchParams)
        .then((res) => {
          if (this.searchParams.pageNo > 1 && res.totalList.length === 0) {
            this.searchParams.pageNo = this.searchParams.pageNo - 1;
          } else {
            this.datasourcesList = [];
            this.datasourcesList = res.totalList;
            this.total = res.total;
            this.isLoading = false;
          }
        })
        .catch((e) => {
          this.isLoading = false;
        });
    },
    _onUpdate() {
      this._debounceGET("false");
    },
  },
  watch: {
    // router
    $route(a) {
      // url no params get instance list
      this.searchParams.pageNo = _.isEmpty(a.query) ? 1 : a.query.pageNo;
    },
  },
  created() {},
  mounted() {},
  components: {
    mList,
    mConditions,
    mSpin,
    mListConstruction,
    mCreateDataSource,
  },
};
</script>
<style lang="scss" rel="stylesheet/scss" scoped>
/deep/ .el-dialog__body {
  padding: 30px 20px;
  color: #606266;
  font-size: 14px;
  word-break: break-all;
  overflow: auto;
}
/deep/ .list-box-f {
  margin-bottom: 20px;
  height: 30px;
}
/deep/ .el-dialog__header {
  background: #f7f8f9;
  border-bottom: 1px solid #e9ebee;
  padding: 14px 20px 13px 20px;
  border-radius: 5px 5px 0 0;
}
</style>
