<template>
  <div class="datasource-popup-model">
    <div class="content-p">
      <div class="create-datasource-model">
        <!-- <m-list-box-f v-if="fieldDisplay('modelName')">
          <template slot="name"><strong>*</strong>存储模型</template>
          <template slot="content" size="small">
            <el-select style="width: 100%" v-model="modelName">
              <el-option
                v-for="item in models"
                :key="item.modelName"
                :value="item.modelName"
                :label="item.modelName"
              >
              </el-option>
            </el-select>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('dataStructureType')">
          <template slot="name"><strong>*</strong>数据读取类型</template>
          <template slot="content" size="small">
            <el-select style="width: 100%" v-model="dataStructureType">
              <el-option
                v-for="item in dataStructureTypes"
                :key="item.dataTypeName"
                :value="item.dataTypeName"
                :label="item.dataTypeName"
              >
              </el-option>
            </el-select>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('dataArea')">
          <template slot="name"><strong>*</strong>数据分类</template>
          <template slot="content" size="small">
            <el-select style="width: 100%" v-model="dataArea">
              <el-option
                v-for="item in dataAreas"
                :key="item.dataAreaName"
                :value="item.dataAreaName"
                :label="item.dataAreaName"
              >
              </el-option>
            </el-select>
          </template>
        </m-list-box-f> -->
        <m-list-box-f v-if="fieldDisplay('type')">
          <template slot="name"><strong>*</strong>数据源</template>
          <template slot="content" size="small">
            <el-select style="width: 100%" v-model="type">
              <el-option
                v-for="item in datasourceTypeList"
                :key="item.value"
                :value="item.value"
                :label="item.label"
              >
              </el-option>
            </el-select>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('name')">
          <template slot="name"><strong>*</strong>数据源名称</template>
          <template slot="content">
            <el-input
              type="input"
              v-model="name"
              maxlength="60"
              size="small"
              placeholder="请输入数据源名称"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f>
          <template slot="name"><strong>*</strong>数据源编码</template>
          <template slot="content" size="small">
            <el-input
              type="input"
              v-model="code"
              maxlength="60"
              size="small"
              placeholder="请输入数据源编码"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('note')" class="h-50">
          <template slot="name">描述</template>
          <template slot="content">
            <el-input
              type="textarea"
              v-model="note"
              size="small"
              placeholder="请输入描述"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('host')">
          <template slot="name"><strong>*</strong>IP</template>
          <template slot="content">
            <el-input
              type="input"
              v-model="host"
              maxlength="60"
              size="small"
              placeholder="请输入IP"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('port')">
          <template slot="name"><strong>*</strong>端口</template>
          <template slot="content">
            <el-input
              type="input"
              v-model="port"
              size="small"
              placeholder="请输入端口号"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('protocol')">
          <template slot="name"><strong>*</strong>协议</template>
          <template slot="content">
            <div class="lh-32">
              <el-radio-group v-model="protocol">
                <el-radio label="ftp">FTP</el-radio>
                <el-radio label="ftps">FTPS</el-radio>
              </el-radio-group>
            </div>
          </template>
        </m-list-box-f>
        <m-list-box-f :class="{ hidden: showPrincipal }">
          <template slot="name"><strong>*</strong>Principal</template>
          <template slot="content">
            <el-input
              type="input"
              v-model="principal"
              size="small"
              :placeholder="$t('请输入Principal')"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f :class="{ hidden: showPrincipal }">
          <template slot="name">krb5.conf</template>
          <template slot="content">
            <el-input
              type="input"
              v-model="javaSecurityKrb5Conf"
              size="small"
              :placeholder="
                $t(
                  'Please enter the kerberos authentication parameter java.security.krb5.conf'
                )
              "
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f :class="{ hidden: showPrincipal }">
          <template slot="name">keytab.username</template>
          <template slot="content">
            <el-input
              type="input"
              v-model="loginUserKeytabUsername"
              size="small"
              :placeholder="
                $t(
                  'Please enter the kerberos authentication parameter login.user.keytab.username'
                )
              "
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f :class="{ hidden: showPrincipal }">
          <template slot="name">keytab.path</template>
          <template slot="content">
            <el-input
              type="input"
              v-model="loginUserKeytabPath"
              size="small"
              :placeholder="
                $t(
                  'Please enter the kerberos authentication parameter login.user.keytab.path'
                )
              "
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('userName')">
          <template slot="name"><strong>*</strong>用户名</template>
          <template slot="content">
            <el-input
              type="input"
              v-model="userName"
              maxlength="60"
              size="small"
              placeholder="请输入用户名"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('password')">
          <template slot="name">密码</template>
          <template slot="content">
            <el-input
              type="password"
              v-model="password"
              size="small"
              placeholder="请输入密码"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('database')">
          <template slot="name"
            ><strong :class="{ hidden: showDatabase }">*</strong
            >数据库名</template
          >
          <template slot="content">
            <el-input
              type="input"
              v-model="database"
              maxlength="60"
              size="small"
              placeholder="请输入数据库名"
            >
            </el-input>
          </template>
        </m-list-box-f>

        <!-- API -->
        <m-list-box-f v-if="fieldDisplay('apiUrl')">
          <template slot="name"><strong>*</strong> URL</template>
          <template slot="content">
            <el-input v-model="apiUrl" size="small"> </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('apiMethod')">
          <template slot="name"><strong>*</strong> Method</template>
          <template slot="content">
            <el-select class="w-100p" size="small" v-model="apiMethod">
              <el-option
                v-for="item in ['POST', 'GET', 'DELETE', 'PUT']"
                :key="item"
                :value="item"
                :label="item"
              ></el-option>
            </el-select>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('dataPath')">
          <template slot="name"><strong>*</strong> 数据路径</template>
          <template slot="content">
            <el-input v-model="dataPath" size="small"></el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="type === 'API'">
          <template slot="name">请求头</template>
          <template slot="content">
            <el-table :data="reqHeaders">
              <el-table-column prop="name" label="参数名称">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="value" label="值">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.value"></el-input>
                </template>
              </el-table-column>
              <el-table-column width="50">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    icon="el-icon-circle-close"
                    @click="
                      () => {
                        reqHeaders.splice(scope.$index, 1);
                      }
                    "
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button
              type="text"
              icon="el-icon-circle-plus-outline"
              @click="reqHeaders.push({ name: '', value: '' })"
              >添加</el-button
            >
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="type === 'API'">
          <template slot="name">请求体</template>
          <template slot="content">
            <el-table :data="reqParams">
              <el-table-column prop="name" label="参数名称">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="value" label="值">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.value"></el-input>
                </template>
              </el-table-column>
              <el-table-column width="50">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    icon="el-icon-circle-close"
                    @click="
                      () => {
                        reqParams.splice(scope.$index, 1);
                      }
                    "
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button
              type="text"
              icon="el-icon-circle-plus-outline"
              @click="reqParams.push({ name: '', value: '' })"
              >添加</el-button
            >
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="type === 'API'">
          <template slot="name">返回参数</template>
          <template slot="content">
            <el-table :data="respParams">
              <el-table-column prop="name" label="参数名称">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="value" label="值">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.value"></el-input>
                </template>
              </el-table-column>
              <el-table-column width="50">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    icon="el-icon-circle-close"
                    @click="
                      () => {
                        respParams.splice(scope.$index, 1);
                      }
                    "
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button
              type="text"
              icon="el-icon-circle-plus-outline"
              @click="respParams.push({ name: '', value: '' })"
              >添加</el-button
            >
          </template>
        </m-list-box-f>

        <!-- Kafka -->
        <!--<m-list-box-f v-if="fieldDisplay('groupId')">-->
        <!--<template slot="name"><strong>*</strong> 组ID</template>-->
        <!--<template slot="content">-->
        <!--<el-input v-model="groupId" size="small"></el-input>-->
        <!--</template>-->
        <!--</m-list-box-f>-->
        <m-list-box-f v-if="fieldDisplay('version')">
          <template slot="name">版本</template>
          <template slot="content">
            <!-- <el-select class="w-100p" size="small" v-model="version">
              <el-option v-for="item in kafkaVersionList" :key="item.value" :value="item.value" :label="item.name"></el-option>
            </el-select> -->
            <el-input placeholder="" v-model="version" size="small"> </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('brokerList')">
          <template slot="name"><strong>*</strong> BrokerList</template>
          <template slot="content">
            <el-input
              placeholder="请输入brokerlist，格式为ip:port,以相连"
              v-model="brokerList"
              size="small"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <!--<m-list-box-f v-if="fieldDisplay('topic')">-->
        <!--<template slot="name">Topic</template>-->
        <!--<template slot="content">-->
        <!--<el-input-->
        <!--v-model="topic"-->
        <!--size="small">-->
        <!--</el-input>-->
        <!--</template>-->
        <!--</m-list-box-f>-->
        <m-list-box-f v-if="type === 'KAFKA'">
          <template slot="name">自定义属性</template>
          <template slot="content">
            <el-table :data="kafkaPropList">
              <el-table-column prop="name" label="属性">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="value" label="值">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.value"></el-input>
                </template>
              </el-table-column>
              <el-table-column width="50">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    icon="el-icon-circle-close"
                    @click="
                      () => {
                        kafkaPropList.splice(scope.$index, 1);
                      }
                    "
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button
              type="text"
              icon="el-icon-circle-plus-outline"
              @click="kafkaPropList.push({ name: '', value: '' })"
              >添加</el-button
            >
          </template>
        </m-list-box-f>

        <m-list-box-f v-if="showConnectType">
          <template slot="name"
            ><strong>*</strong>{{ $t("Oracle Connect Type") }}</template
          >
          <template slot="content">
            <el-radio-group
              v-model="connectType"
              size="small"
              style="vertical-align: sub"
            >
              <el-radio :label="'ORACLE_SERVICE_NAME'">{{
                $t("Oracle Service Name")
              }}</el-radio>
              <el-radio :label="'ORACLE_SID'">{{ $t("Oracle SID") }}</el-radio>
            </el-radio-group>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="fieldDisplay('other')">
          <template slot="name">jdbc连接参数</template>
          <template slot="content">
            <el-input
              type="textarea"
              v-model="other"
              :autosize="{ minRows: 2 }"
              size="small"
              :placeholder="_rtOtherPlaceholder()"
            >
            </el-input>
          </template>
        </m-list-box-f>
        <m-list-box-f v-if="type === 'FTP'">
          <template slot="name">高级配置</template>
          <template slot="content">
            <el-table :data="propList">
              <el-table-column prop="name" label="属性">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.name"></el-input>
                </template>
              </el-table-column>
              <el-table-column prop="value" label="值">
                <template slot-scope="{ row }">
                  <el-input size="small" v-model="row.value"></el-input>
                </template>
              </el-table-column>
              <el-table-column width="50">
                <template slot-scope="scope">
                  <el-button
                    type="text"
                    size="small"
                    icon="el-icon-circle-close"
                    @click="
                      () => {
                        propList.splice(scope.$index, 1);
                      }
                    "
                  ></el-button>
                </template>
              </el-table-column>
            </el-table>
            <el-button
              type="text"
              icon="el-icon-circle-plus-outline"
              @click="propList.push({ name: '', value: '' })"
              >添加</el-button
            >
          </template>
        </m-list-box-f>
      </div>
    </div>
    <div class="bottom-p">
      <basic-form-button
        class="mar-0"
        :isForm="false"
        :buttons="buttons"
        :loadings="loadings"
        @cancel="_close"
        @submit="_ok"
        @testConnect="_testConnect"
      ></basic-form-button>

      <!-- <el-button type="text" ize="mini" @click="_close()"> {{$t('Cancel')}} </el-button>
      <el-button type="success" size="mini" round @click="_testConnect()" :loading="testLoading">{{testLoading ? 'Loading...' : $t('Test Connect')}}</el-button>
      <el-button type="primary" size="small"  :loading="spinnerLoading" @click="_ok()">{{spinnerLoading ? 'Loading...' :item ? `${$t('Edit')}` : `${$t('Submit')}`}} </el-button> -->
    </div>
  </div>
</template>
<script>
const isJson = (str) => {
  if (typeof str === "string") {
    try {
      const obj = JSON.parse(str);
      if (typeof obj === "object" && obj) {
        return true;
      } else {
        return false;
      }
    } catch (e) {
      return false;
    }
  }
};
import i18n from "@/lang";
import store from "@/store";
import mListBoxF from "../../listBoxF/listBoxF";
import { fieldDisplay, fieldVerification } from "./field-display";
import datasourceTypeList from "@/config/datasourceTypeList";
export default {
  name: "create-datasource",
  data() {
    return {
      // api
      apiUrl: "",
      apiMethod: "",
      reqHeaders: [],
      reqParams: [],
      respParams: [],
      dataPath: "",
      // kafka
      version: "",
      kafkaVersionList: [
        { name: "V-1.0", value: "1.0" },
        { name: "V-2.0", value: "2.0" },
      ],
      brokerList: "",
      topic: "",
      kafkaPropList: [],
      groupId: "",

      dataAreas: [],
      dataStructureTypes: [],
      models: [],
      buttons: [
        {
          text: "测试连接",
          code: "testConnect",
          icon: "",
          type: "success",
        },
        { text: "", code: "cancel", icon: "", type: "" },
        { text: "", code: "submit", icon: "", type: "primary" },
      ],
      loadings: {
        submit: false,
        testConnect: false,
      },
      modelName: "",
      store,
      // btn loading
      spinnerLoading: false,
      // Data source type
      type: "MYSQL",
      // name
      name: "",

      dataArea: "",
      dataStructureType: "",
      code: "",
      // description
      note: "",
      // host
      host: "",
      // port
      port: "",
      propList: [],
      protocol: "ftp",
      // data storage name
      database: "",
      // principal
      principal: "",
      // java.security.krb5.conf
      javaSecurityKrb5Conf: "",
      // login.user.keytab.username
      loginUserKeytabUsername: "",
      // login.user.keytab.path
      loginUserKeytabPath: "",
      // database username
      userName: "",
      // Database password
      password: "",
      // Database connect type
      connectType: "",
      // Jdbc connection parameter
      other: "",
      // btn test loading
      testLoading: false,
      showPrincipal: true,
      showDatabase: false,
      showConnectType: false,
      isShowPrincipal: true,
      prePortMapper: {},
      datasourceTypeList: datasourceTypeList,
      // [
      //   {
      //     value: 'MYSQL',
      //     label: 'MYSQL'
      //   },
      //   {
      //     value: 'POSTGRESQL',
      //     label: 'POSTGRESQL'
      //   },
      //   {
      //     value: 'HIVE',
      //     label: 'HIVE/IMPALA'
      //   },
      //   {
      //     value: 'SPARK',
      //     label: 'SPARK'
      //   },
      //   {
      //     value: 'CLICKHOUSE',
      //     label: 'CLICKHOUSE'
      //   },
      //   {
      //     value: 'ORACLE',
      //     label: 'ORACLE'
      //   },
      //   {
      //     value: 'SQLSERVER',
      //     label: 'SQLSERVER'
      //   },
      //   {
      //     value: 'DB2',
      //     label: 'DB2'
      //   },
      //   {
      //     value: 'PRESTO',
      //     label: 'PRESTO'
      //   },
      //   {
      //     value: 'FTP',
      //     label: 'FTP'
      //   },
      //   {
      //     value: 'API',
      //     label: 'API'
      //   },
      //   {
      //     value: 'KAFKA',
      //     label: 'KAFKA'
      //   }
      // ]
    };
  },
  props: {
    item: Object,
  },

  methods: {
    fieldDisplay(name) {
      return fieldDisplay(name, this.type);
    },
    consoleLog(a) {
      console.log(a);
    },
    _rtOtherPlaceholder() {
      return `${i18n.$t(
        "请输入"
      )} {"key1":"value1","key2":"value2"...} ${i18n.$t("连接参数")}`;
    },
    /**
     * submit
     */
    _ok() {
      if (this._verification()) {
        this._verifName().then((res) => {
          this._submit();
        });
      }
    },
    /**
     * close
     */
    _close() {
      this.$emit("close");
    },
    /**
     * return param
     */
    _rtParam() {
      return {
        modeltype: this.modelName,
        protocol: this.protocol,
        type: this.type,
        name: this.name,
        note: this.note,
        host: this.host,
        port: this.port,
        database: this.database,
        principal: this.principal,
        javaSecurityKrb5Conf: this.javaSecurityKrb5Conf,
        loginUserKeytabUsername: this.loginUserKeytabUsername,
        loginUserKeytabPath: this.loginUserKeytabPath,
        userName: this.userName,
        password: this.password,
        connectType: this.connectType,
        other: this.other,
        dataArea: this.dataArea,
        dataStructureType: this.dataStructureType,
        code: this.code,
        // API
        apiUrl: this.apiUrl,
        apiMethod: this.apiMethod,
        dataPath: this.dataPath,
        reqHeaders: JSON.stringify(this.reqHeaders),
        reqParams: JSON.stringify(this.reqParams),
        respParams: JSON.stringify(this.respParams),

        // kafka
        version: this.version,
        brokerList: this.brokerList,
        //topic: this.topic,
        //groupId: this.groupId
      };
    },
    /**
     * test connect
     */
    _testConnect() {
      if (this._verification()) {
        this.loadings.testConnect = true;
        this.$store
          .dispatch("datasource/connectDatasources", this._rtParam())
          .then((res) => {
            setTimeout(() => {
              this.$message.success(res.msg);
              this.loadings.testConnect = false;
            }, 800);
          })
          .catch((e) => {
            this.$message.error(e.msg || "");
            this.loadings.testConnect = false;
          });
        this.loadings.testConnect = true;
      }
      this.loadings.testConnect = true;
    },
    /**
     * Verify that the data source name exists
     */
    _verifName() {
      return new Promise((resolve, reject) => {
        if (this.item && this.name === this.item.name) {
          resolve();
          return;
        }
        this.$store
          .dispatch("datasource/verifyName", { name: this.name })
          .then((res) => {
            resolve();
          })
          .catch((e) => {
            this.$message.error(e.msg || "");
            reject(e);
          });
      });
    },
    /**
     * verification
     */
    _verification() {
      console.log("123");
      if (!fieldVerification(this, this.type)) {
        this.$message.warning(`*标记为必填项，请完善表单。`);
        this.loadings.submit = false;
        console.log(this.loadings.submit);
        return false;
      } else {
        this.loadings.submit = false;
      }

      // fieldVerification(this , this.type);
      // if (!this.name) {
      //   this.$message.warning(`${i18n.$t('Please enter resource name')}`)
      //   return false
      // }
      // if (!this.host) {
      //   this.$message.warning(`${i18n.$t('Please enter IP/hostname')}`)
      //   return false
      // }
      // if (!this.port) {
      //   this.$message.warning(`${i18n.$t('Please enter port')}`)
      //   return false
      // }
      // if (!this.userName) {
      //   this.$message.warning(`${i18n.$t('Please enter user name')}`)
      //   return false
      // }

      let databaseExcludeTypes = ["FTP", "API", "KAFKA"];

      if (
        !this.database &&
        this.showDatabase === false &&
        !databaseExcludeTypes.includes(this.type)
      ) {
        this.$message.warning(`${i18n.$t("Please enter database name")}`);
        return false;
      }
      if (this.other) {
        if (!isJson(this.other)) {
          this.$message.warning(
            `${i18n.$t(
              "jdbc connection parameters is not a correct JSON format"
            )}`
          );
          return false;
        }
      }
      return true;
    },
    /**
     * submit => add/update
     */
    _submit() {
      console.log("submit");
      let param = this._rtParam();
      this.loadings.submit = true;
      // edit
      if (this.item) {
        param.id = this.item.id;
      }
      this.$store
        .dispatch(
          `datasource/${this.item ? "updateDatasource" : "createDatasources"}`,
          param
        )
        .then((res) => {
          this.$message.success(res.msg);
          this.loadings.submit = false;
          this.$emit("onUpdate");
        })
        .catch((e) => {
          this.$message.error(e.msg || "");
          this.loadings.submit = false;
        });
    },
    /**
     * Get modified data
     */
    _getEditDatasource() {
      console.log("查询");
      this.$store
        .dispatch("datasource/getEditDatasource", { id: this.item.id })
        .then((res) => {
          console.log(res);
          this.type = res.type;
          this.name = res.name;
          this.note = res.note;
          this.host = res.host;
          this.modelName = res.modeltype;
          this.dataArea = res.dataArea;
          this.dataStructureType = res.dataStructureType;
          this.protocol = res.protocol || "";
          this.code = res.code;
          this.apiUrl = res.apiUrl || "";
          this.apiMethod = res.apiMethod || "";
          this.dataPath = res.dataPath || "";
          //this.groupId = res.groupId || '';

          this.reqHeaders = res.reqHeaders ? JSON.parse(res.reqHeaders) : [];
          this.respParams = res.respParams ? JSON.parse(res.respParams) : [];
          this.reqParams = res.reqParams ? JSON.parse(res.reqParams) : [];

          this.version = res.version || "";
          this.brokerList = res.brokerList || "";
          //this.topic = res.topic || '';

          // When in Editpage, Prevent default value overwrite backfill value
          setTimeout(() => {
            this.port = res.port;
          }, 0);

          this.principal = res.principal;
          this.javaSecurityKrb5Conf = res.javaSecurityKrb5Conf;
          this.loginUserKeytabUsername = res.loginUserKeytabUsername;
          this.loginUserKeytabPath = res.loginUserKeytabPath;
          this.database = res.database;
          this.userName = res.userName;
          this.password = res.password;
          this.connectType = res.connectType;
          this.other =
            JSON.stringify(res.other) === "{}" ? "" : JSON.stringify(res.other);
        })
        .catch((e) => {
          this.$message.error(e.msg || "");
        });
    },
    /**
     * Set default port for each type.
     */
    _setDefaultValues(value) {
      // Default type is MYSQL
      let type = this.type || "MYSQL";

      let defaultPort = this._getDefaultPort(type);

      // Backfill the previous input from memcache
      let mapperPort = this.prePortMapper[type];

      this.port = mapperPort || defaultPort;
    },

    /**
     * Get default port by type
     */
    _getDefaultPort(type) {
      let defaultPort = "";
      switch (type) {
        case "MYSQL":
          defaultPort = "3306";
          break;
        case "POSTGRESQL":
          defaultPort = "5432";
          break;
        case "HIVE":
          defaultPort = "10000";
          break;
        case "SPARK":
          defaultPort = "10015";
          break;
        case "CLICKHOUSE":
          defaultPort = "8123";
          break;
        case "ORACLE":
          defaultPort = "1521";
          break;
        case "SQLSERVER":
          defaultPort = "1433";
          break;
        case "DB2":
          defaultPort = "50000";
          break;
        case "PRESTO":
          defaultPort = "8080";
          break;
        case "FTP":
          defaultPort = "21";
          break;
        case "HDFS":
          defaultPort = "8020";
          break;
        default:
          break;
      }
      return defaultPort;
    },
  },
  created() {
    // Backfill
    if (this.item && this.item.id) {
      this._getEditDatasource();
    }

    this._setDefaultValues();
  },
  watch: {
    type(value) {
      if (value === "POSTGRESQL") {
        this.showDatabase = true;
      } else {
        this.showDatabase = false;
      }

      if (value === "ORACLE" && !this.item.id) {
        this.showConnectType = true;
        this.connectType = "ORACLE_SERVICE_NAME";
      } else if (value === "ORACLE" && this.item.id) {
        this.showConnectType = true;
      } else {
        this.showConnectType = false;
      }
      // Set default port for each type datasource
      this._setDefaultValues(value);

      return new Promise((resolve, reject) => {
        this.$store
          .dispatch("datasource/getKerberosStartupState")
          .then((res) => {
            this.isShowPrincipal = res;
            if (
              (value === "HIVE" || value === "SPARK") &&
              this.isShowPrincipal === true
            ) {
              this.showPrincipal = false;
            } else {
              this.showPrincipal = true;
            }
          })
          .catch((e) => {
            this.$message.error(e.msg || "");
            reject(e);
          });
      });
    },
    /**
     * Cache the previous input port for each type datasource
     * @param value
     */
    port(value) {
      this.prePortMapper[this.type] = value;
    },
  },

  mounted() {
    // this.store.dispatch("datasource/queryDataModelAll").then((res) => {
    //   this.models = res.data || [];
    // });
    // this.store.dispatch("datasource/queryDataAreaAll").then((res) => {
    //   this.dataAreas = res.data || [];
    // });
    // this.store.dispatch("datasource/queryDataStructureTypeAll").then((res) => {
    //   this.dataStructureTypes = res.data || [];
    // });
  },
  components: { mListBoxF },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.datasource-popup-model {
  background: #fff;
  border-radius: 3px;

  .top-p {
    height: 70px;
    line-height: 70px;
    border-radius: 3px 3px 0 0;
    padding: 0 20px;
    > span {
      font-size: 20px;
    }
  }
  .bottom-p {
    text-align: right;
    height: 72px;
    line-height: 72px;
    border-radius: 0 0 3px 3px;
    padding: 0 20px;
    .mar-0 {
      margin: 0px !important;
    }
  }
  .content-p {
    min-width: 850px;
    min-height: 100px;
    .list-box-f {
      .text {
        width: 166px;
      }
      .cont {
        width: calc(100% - 186px);
      }
    }
  }
  .radio-label-last {
    margin-left: 0px !important;
  }
}
</style>
