<template>
  <!-- <div class="home-main list-construction-model"> -->
  <basic-container class="home-main h-100p pad-10 bg-fff">
    <!-- <div class="content-title">
      <span>{{title}}</span>
      <slot name="operation"></slot>
    </div> -->
    <div class="conditions-box">
      <slot name="conditions"> </slot>
    </div>
    <div class="list-box">
      <slot name="content"> </slot>
    </div>
  </basic-container>
  <!-- </div> -->
</template>
<script>
export default {
  name: "list-construction",
  data() {
    return {};
  },
  props: {
    title: String,
  },
};
</script>

<style lang="scss" rel="stylesheet/scss">
.bg-fff {
  background: #fff;
}
</style>
