<template>
  <div class="list-model">
    <div class="table-box">
      <el-table :data="list" size="mini" style="width: 100%">
        <el-table-column
          type="index"
          :label="$t('#')"
          width="50"
        ></el-table-column>
        <!-- <el-table-column
          prop="name"
          :label="$t('Datasource Name')"
        ></el-table-column> -->
        <el-table-column prop="name" label="数据源"></el-table-column>
        <el-table-column prop="userName" label="数据源名称"></el-table-column>
        <!-- <el-table-column prop="modeltype" label="存储模型"></el-table-column>
        <el-table-column
          prop="dataStructureType"
          label="数据读取类型"
        ></el-table-column>
        <el-table-column prop="dataArea" label="数据分类"></el-table-column> -->
        <el-table-column prop="type" label="数据源类型"></el-table-column>
        <el-table-column label="数据源参数">
          <template slot-scope="scope">
            <div>
              <m-tooltips-JSON
                :JSON="JSON.parse(scope.row.connectionParams)"
                :id="scope.row.id"
              >
                <span slot="reference">
                  <el-button size="small" type="text">查看详情</el-button>
                </span>
              </m-tooltips-JSON>
            </div>
          </template>
        </el-table-column>
        <el-table-column label="描述" min-width="100">
          <template slot-scope="scope">
            <span>{{ scope.row.note | filterNull }}</span>
          </template>
        </el-table-column>
        <el-table-column label="创建时间" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.createTime | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="更新时间" min-width="120">
          <template slot-scope="scope">
            <span>{{ scope.row.updateTime | formatDate }}</span>
          </template>
        </el-table-column>
        <el-table-column label="操作" fixed="right" align="center" width="150">
          <template slot-scope="scope">
            <el-button
              class="mar-r-5"
              type="text"
              size="mini"
              icon="el-icon-edit-outline"
              @click="_edit(scope.row)"
              >编辑</el-button
            >
            <el-popconfirm
              :confirmButtonText="$t('Confirm')"
              :cancelButtonText="$t('Cancel')"
              icon="el-icon-info"
              iconColor="red"
              title="删除"
              @confirm="_delete(scope.row, scope.row.id)"
            >
              <el-button
                type="text"
                size="mini"
                icon="el-icon-delete"
                slot="reference"
                >删除</el-button
              >
            </el-popconfirm>
          </template>
        </el-table-column>
      </el-table>
    </div>
  </div>
</template>
<script>
import mTooltipsJSON from "../../tooltipsJSON/tooltipsJSON";

export function findComponentDownward(context, componentName) {
  const childrens = context.$children;
  let children = null;
  if (childrens.length) {
    childrens.forEach((child) => {
      const name = child.$options.name;
      if (name === componentName) {
        children = child;
      }
    });
    for (let i = 0; i < childrens.length; i++) {
      const child = childrens[i];
      const name = child.$options.name;
      if (name === componentName) {
        children = child;
        break;
      } else {
        children = findComponentDownward(child, componentName);
        if (children) break;
      }
    }
  }
  return children;
}
import { mapActions } from "vuex";

export default {
  name: "datasource-list",
  data() {
    return {
      // list
      list: [],
    };
  },
  props: {
    // External incoming data
    datasourcesList: Array,
    // current page number
    pageNo: Number,
    // Total number of articles
    pageSize: Number,
  },
  methods: {
    ...mapActions("datasource", ["deleteDatasource"]),
    /**
     * Delete current line
     */
    _delete(item, i) {
      this.deleteDatasource({
        id: item.id,
      })
        .then((res) => {
          this.$emit("on-update");
          this.$message.success(res.msg);
        })
        .catch((e) => {
          this.$message.error(e.msg || "");
        });
    },
    /**
     * edit
     */
    _edit(item) {
      findComponentDownward(this.$root, "datasource-indexP")._create(item);
    },
  },
  watch: {
    /**
     * Monitor external data changes
     */
    datasourcesList(a) {
      this.list = [];
      setTimeout(() => {
        this.list = a;
      });
    },
  },
  created() {
    this.list = this.datasourcesList;
  },
  mounted() {},
  components: { mTooltipsJSON },
};
</script>
